/* eslint-disable vue/return-in-computed-property */
<template>
  <div class="goodsDetail" v-if="render">
    <div class="l-boxs box-m-t-4" v-if="swiperList.length > 0">
      <swiper :list="swiperList"></swiper>
    </div>
    <div class="l-boxs box-m-t-10">
      <cardV1
        :title="goodsInfo.title"
        :deduct="goodsInfo.deduct"
        :sales_number="goodsInfo.sales_number"
        :product_price="goodsInfo.product_price"
        :market_price="goodsInfo.market_price"
        :mail_type="goodsInfo.mail_type"
        :return_exchange="goodsInfo.return_exchange"
        :show_will_send="goodsInfo.show_will_send"
        :commission_money="goodsInfo.commission_money"
      ></cardV1>
    </div>
    <div class="share box-m-t-10 l-flex-s0" @click="share">
      <span>分享好友，秒赚佣金</span>
      <img :src="require('@STATIC/image/icons/arrowRight.png')" />
    </div>
    <div
      class="invite-user box-m-t-10 l-flex-s0"
      v-if="typeof inviteInfo.avatarUrl != 'undefined'"
    >
      <div class="invite-user-warning">该商品由以下会员为您分享</div>
      <div class="invite-user-info">
        <img :src="inviteInfo.avatarUrl" />
        {{ inviteInfo.nickName }}
      </div>
    </div>

    <div class="parse box-m-t-10">
      <nut-tab
        class="tabClass"
        @tab-switch="tabSwitch"
        :line-width="100"
        :is-show-line="false"
      >
        <nut-tab-panel tab-title="商品详情">
          <!-- <pre>{{ goodsInfo.site_point_setting }}</pre> -->

          <parseV1 :content="goodsInfo.content"></parseV1>
        </nut-tab-panel>
        <nut-tab-panel
          v-if="
            typeof areaSetting.no_send_area != 'undefined' &&
            areaSetting.no_send_area.length > 0
          "
          tab-title="不发货区域"
        >
          <p style="font-weight: bold">以下区域为不发货的区域:</p>
          <ul>
            <li
              style="margin-bottom: 10px"
              v-for="(item, index) in areaSetting.no_send_area_text"
            >
              {{ item }}
            </li>
          </ul>
        </nut-tab-panel>
        <nut-tab-panel
          v-if="
            typeof areaSetting.need_shipping_fee_area != 'undefined' &&
            areaSetting.need_shipping_fee_area.length > 0
          "
          tab-title="补邮费区域"
        >
          <p style="font-weight: bold">
            以下区域发货需要补邮费:¥{{ areaSetting.need_shipping_fee }}元
          </p>
          <ul>
            <li
              style="margin-bottom: 10px"
              v-for="(item, index) in areaSetting.need_shipping_fee_area_text"
            >
              {{ item }}
            </li>
          </ul>
        </nut-tab-panel>
        <nut-tab-panel
          v-if="
            typeof goodsInfo.site_point_setting != 'undefined' &&
            goodsInfo.site_point_setting.length > 0
          "
          tab-title="可提货自提点"
        >
          <p style="font-weight: bold">
            除以下自提点以为，其他自提点均无法提货
          </p>
          <ul>
            <li
              style="margin-bottom: 10px"
              v-for="(item, index) in goodsInfo.site_point_setting"
            >
              {{ item.site_name }}
            </li>
          </ul>
        </nut-tab-panel>
      </nut-tab>
    </div>

    <div class="area"></div>
    <div class="foot">
      <footbarV1
        :isFollow="isFollow"
        :disableCart="goodsInfo.goods_plan == 1"
        @follow="follow"
        @addCar="addCar"
        @goCar="goCar"
        @onBuy="buy"
      ></footbarV1>
    </div>
    <rulesV1
      :show="rule.show"
      :cover="goodsInfo.cover"
      :unit_price="goodsInfo.market_price"
      :number="number"
      :stock="goodsInfo.stock_number"
      :goods_products="goodsInfo.goods_products"
      :goods_specs="goodsInfo.goods_specs"
      :goods_spec_checkeds="goods_spec_checkeds"
      :goods_containers="goods_containers"
      @onClose="rule.show = false"
      @onConfirm="onConfirm"
      @changeGoodsInfo="changeGoodsInfo"
    ></rulesV1>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import swiper from "@T/community/swiper/swiperV1";
import cardV1 from "@T/community/card/cardV1";
import parseV1 from "@C/parse/parseV1";
import footbarV1 from "@T/community/footbar/footbarV1.vue";
import rulesV1 from "@T/community/popup/rulesV1";

export default {
  name: "goodsDetail",
  components: {
    swiper,
    cardV1,
    parseV1,
    footbarV1,
    rulesV1,
  },
  data() {
    return {
      id: 0, //商品id
      number: 1, //商品数量
      //展示商品规格选择
      rule: {
        show: false,
        type: 0, //0 加入购物车 1立即下单
      },
      goods_spec_checkeds: {}, //已经选择的规格
      goodsInfo: {
        cover: "",
        cover_images: [],
        video_path: "",
        title: "",
        deduct: 0,
        market_price: "",
        product_price: "",
        sales_number: 0,
        mail_type: null,
        favorite_id: 0,
        stock_number: 0,
        goods_products: {},
        goods_specs: [],
        goods_plan: 0,
        site_point_setting: [],
      },
      inviteInfo: {},
      hasInviteUser: false,
      goods_containers: {},
      areaSetting: {},
      render: false,
    };
  },
  computed: {
    swiperList() {
      let list = [],
        videoList = [],
        imgList = [];
      if (this.goodsInfo?.video_path != "") {
        videoList.push({
          type: "video",
          url: this.goodsInfo.video_path,
        });
      }
      imgList = this.goodsInfo?.cover_images.map((val) => ({
        type: "image",
        url: val,
      }));
      list = [...videoList, ...imgList];
      return list;
    },
    isFollow() {
      if (this.goodsInfo.favorite_id > 0) {
        return true;
      }
      return false;
    },
  },
  created() {
    document.title = "商品详情";
    this.id = this.$route.query.goodsId || 0;
    this.getGoodsInfo();
  },
  methods: {
    ...mapActions("community_order", ["reset"]),
    async getGoodsInfo() {
      try {
        let query = await this.$api.community.goods.getInfo({
          id: this.id,
        });

        this.goodsInfo = query.data;
        console.log("this.goodsInfo", this.goodsInfo);
        if (typeof this.goodsInfo.invite_user != "undefined") {
          this.hasInviteUser = true;
          this.inviteInfo = this.goodsInfo.invite_user;
        }
        this.goods_containers = this.goodsInfo?.goods_containers || {};
        this.areaSetting = this.goodsInfo.area_setting || {};
        this.render = true;
      } catch (error) {
        //
      }
    },
    async follow() {
      try {
        let query = null;
        if (this.goodsInfo.favorite_id) {
          query = await this.$api.community.goods.followDels({
            favorite_ids: [this.goodsInfo.favorite_id].join(","),
          });
          this.goodsInfo.favorite_id = 0;
        } else {
          query = await this.$api.community.goods.followAdd({
            goods_id: this.id,
          });
          this.goodsInfo.favorite_id = Number(query.data);
        }
      } catch (error) {
        console.log("follow", error);
      }
    },
    goCar() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/community/cart",
        });
      } else {
        this.$router.push({ path: "cart" });
      }
    },
    async addCar() {
      this.rule = {
        show: true,
        type: 0,
      };
    },
    buy() {
      // console.log("buy");
      this.rule = {
        show: true,
        type: 1,
      };
    },
    onConfirm(param) {
      // console.log(param);
      this.reset();
      if (this.rule.type == 0) {
        this.onAddCar(param);
      } else {
        this.onBuy(param);
      }
    },
    onBuy(param) {
      let query = {
        ...param,
        specs: JSON.stringify(param.specs),
        num: param.num,
        goods_id: this.id,
        goods_plan: this.goodsInfo.goods_plan,
      };
      if (this.$platform.wxsdk.isWechat()) {
        query = encodeURIComponent(JSON.stringify(query));
        if (this.goodsInfo.goods_type_id == 2) {
          this.$platform.wxsdk.wxRoute({
            type: "navigateTo",
            url: "/web/community/join-order-confirm?query=" + query,
          });
        } else {
          this.$platform.wxsdk.wxRoute({
            type: "navigateTo",
            url: "/web/community/order-confrim?query=" + query,
          });
        }
      } else {
        this.$router.push({
          path: "order-confrim",
          query: query,
        });
      }
    },
    async onAddCar(param) {
      try {
        if (Object.keys(param.specs).length == 0) {
          //单个规格
          await this.$api.community.cart.addGoods({
            goods_id: this.id,
            number: param.num,
          });
        } else {
          await this.$api.community.cart.addGoods({
            goods_id: this.id,
            spec_ids: Object.values(param.specs)
              .sort((a, b) => a - b)
              .join(","),
            number: param.num,
          });
          //多规格
        }
        this.$toast.text("加入购物车成功", {
          id: 1,
          duration: 4000,
        });
      } catch (error) {
        this.$toast.text("加入购物车失败");
      }
    },
    share() {
      let query = encodeURIComponent(JSON.stringify(this.goodsInfo));
      this.$platform.wxsdk.wxRoute({
        type: "navigateTo",
        url: "/web/share/share?query=" + query,
      });
    },
    changeGoodsInfo(goods) {
      this.goodsInfo = {
        ...this.goodsInfo,
        ...goods,
      };
      this.id = goods.goods_id;
    },
    tabSwitch: function (index, event) {
      console.log(index + "--" + event);
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(goodsDetail) {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

@include b(card) {
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  @include m(p20) {
    padding: 20px;
  }
  @include m(cell) {
    padding: 13px 20px;
  }
}

@include b(share) {
  box-sizing: border-box;
  background: #fff;
  width: 345px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span {
    margin-left: 17px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #eb6100;
  }
  & > img {
    margin-right: 9px;
    width: 11px;
    height: 11px;
  }
}

@include b(parse) {
  width: 345px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 16px 17px;
  @include e(title) {
    margin-bottom: 12px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  @include e(content) {
    width: 100%;
    overflow: scroll;
  }
}

@include b(foot) {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  background: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.area {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
}

.invite-user {
  width: 305px;
  background: #fff;
  padding: 13px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  .invite-user-warning {
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #eb6100;
    margin-bottom: 10px;
  }

  .invite-user-info {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 10px;
    }
  }
}

::v-deep .tabClass {
  .nut-tab {
    background: none !important;
    border: 0;
    padding: 0;
  }

  .nut-tab-link {
    justify-content: flex-start;
    height: 30px;
  }

  .nut-tab-active {
    font-weight: bold !important;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.render)?_c('div',{staticClass:"goodsDetail"},[(_vm.swiperList.length > 0)?_c('div',{staticClass:"l-boxs box-m-t-4"},[_c('swiper',{attrs:{"list":_vm.swiperList}})],1):_vm._e(),_c('div',{staticClass:"l-boxs box-m-t-10"},[_c('cardV1',{attrs:{"title":_vm.goodsInfo.title,"deduct":_vm.goodsInfo.deduct,"sales_number":_vm.goodsInfo.sales_number,"product_price":_vm.goodsInfo.product_price,"market_price":_vm.goodsInfo.market_price,"mail_type":_vm.goodsInfo.mail_type,"return_exchange":_vm.goodsInfo.return_exchange,"show_will_send":_vm.goodsInfo.show_will_send,"commission_money":_vm.goodsInfo.commission_money}})],1),_c('div',{staticClass:"share box-m-t-10 l-flex-s0",on:{"click":_vm.share}},[_c('span',[_vm._v("分享好友，秒赚佣金")]),_c('img',{attrs:{"src":require('@STATIC/image/icons/arrowRight.png')}})]),(typeof _vm.inviteInfo.avatarUrl != 'undefined')?_c('div',{staticClass:"invite-user box-m-t-10 l-flex-s0"},[_c('div',{staticClass:"invite-user-warning"},[_vm._v("该商品由以下会员为您分享")]),_c('div',{staticClass:"invite-user-info"},[_c('img',{attrs:{"src":_vm.inviteInfo.avatarUrl}}),_vm._v(" "+_vm._s(_vm.inviteInfo.nickName)+" ")])]):_vm._e(),_c('div',{staticClass:"parse box-m-t-10"},[_c('nut-tab',{staticClass:"tabClass",attrs:{"line-width":100,"is-show-line":false},on:{"tab-switch":_vm.tabSwitch}},[_c('nut-tab-panel',{attrs:{"tab-title":"商品详情"}},[_c('parseV1',{attrs:{"content":_vm.goodsInfo.content}})],1),(
          typeof _vm.areaSetting.no_send_area != 'undefined' &&
          _vm.areaSetting.no_send_area.length > 0
        )?_c('nut-tab-panel',{attrs:{"tab-title":"不发货区域"}},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v("以下区域为不发货的区域:")]),_c('ul',_vm._l((_vm.areaSetting.no_send_area_text),function(item,index){return _c('li',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),(
          typeof _vm.areaSetting.need_shipping_fee_area != 'undefined' &&
          _vm.areaSetting.need_shipping_fee_area.length > 0
        )?_c('nut-tab-panel',{attrs:{"tab-title":"补邮费区域"}},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" 以下区域发货需要补邮费:¥"+_vm._s(_vm.areaSetting.need_shipping_fee)+"元 ")]),_c('ul',_vm._l((_vm.areaSetting.need_shipping_fee_area_text),function(item,index){return _c('li',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),(
          typeof _vm.goodsInfo.site_point_setting != 'undefined' &&
          _vm.goodsInfo.site_point_setting.length > 0
        )?_c('nut-tab-panel',{attrs:{"tab-title":"可提货自提点"}},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(" 除以下自提点以为，其他自提点均无法提货 ")]),_c('ul',_vm._l((_vm.goodsInfo.site_point_setting),function(item,index){return _c('li',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" "+_vm._s(item.site_name)+" ")])}),0)]):_vm._e()],1)],1),_c('div',{staticClass:"area"}),_c('div',{staticClass:"foot"},[_c('footbarV1',{attrs:{"isFollow":_vm.isFollow,"disableCart":_vm.goodsInfo.goods_plan == 1},on:{"follow":_vm.follow,"addCar":_vm.addCar,"goCar":_vm.goCar,"onBuy":_vm.buy}})],1),_c('rulesV1',{attrs:{"show":_vm.rule.show,"cover":_vm.goodsInfo.cover,"unit_price":_vm.goodsInfo.market_price,"number":_vm.number,"stock":_vm.goodsInfo.stock_number,"goods_products":_vm.goodsInfo.goods_products,"goods_specs":_vm.goodsInfo.goods_specs,"goods_spec_checkeds":_vm.goods_spec_checkeds,"goods_containers":_vm.goods_containers},on:{"onClose":function($event){_vm.rule.show = false},"onConfirm":_vm.onConfirm,"changeGoodsInfo":_vm.changeGoodsInfo}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }